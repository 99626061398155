import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatUSD } from '@shared/lib/numbers';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { Body, BodyMediumSemiBold, BodySmall, } from '@src/components/styled/Typography';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const DetailsContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    minWidth: '200px',
    padding: props.theme.spacing_sizes.m,
    backgroundColor: '#FBFBFC',
    borderRadius: props.theme.shape.borderRadius,
    gap: props.theme.spacing_sizes.s,
}));
const BalanceChangeRow = styled(ContainerRow)((props) => ({
    height: 'auto',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.s,
}));
const BalanceChange = withPercentageIncomeBadge(BodySmall);
const BalanceChangeDetails = ({ balance, percent_change, balance_change, haveWallets = false, }) => {
    const { t } = useTranslation();
    return (_jsxs(Container, { children: [_jsx(Body, { children: `${t('overview.balance')} / ${t('overview.full_day_change')}:` }), _jsxs(DetailsContainer, { children: [_jsx(BodyMediumSemiBold, { children: formatUSD(balance) }), haveWallets && (_jsxs(BalanceChangeRow, { children: [_jsx(BalanceChange, { percentageIncome: percent_change }), _jsx(BalanceChange, { percentageIncome: balance_change, incomeSymbol: 'sign' })] }))] })] }));
};
export default BalanceChangeDetails;
